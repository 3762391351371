<template>
  <vx-card :title="id === null ? $t('students_create') : $t('students_edit')">
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          :label="$t('name')"
          v-model="name"
          name="nome"
          v-validate="'required|max:150'"
          maxlength="150"
        />
        <span class="text-danger text-sm" v-show="errors.has('nome')">{{
          errors.first('nome')
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col sm:w-1/5">
        <label>{{ $t('birthdate') }}</label>
        <datetime
          v-model="birthdate"
          input-class="vs-inputx vs-input--input normal"
          type="date"
          format="dd/MM/yyyy"
          value-zone="America/Sao_Paulo"
          use24-hour
        >
        </datetime>
        <span class="text-danger text-sm" v-show="errors.has('birthdate')">{{
          errors.first('birthdate')
        }}</span>
      </div>
      <div class="vx-col sm:w-1/5">
        <div class="centerx">
          <label>{{ $t('gender') }}</label
          ><br />
          <vs-radio v-model="gender" vs-value="feminine">
            {{ $t('feminine') }}
          </vs-radio>
          <vs-radio v-model="gender" vs-value="male">
            {{ $t('male') }}
          </vs-radio>
          <span class="text-danger text-sm" v-show="errors.has('gender')">{{
            errors.first('gender')
          }}</span>
        </div>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          v-validate="'required|email'"
          data-vv-validate-on="blur"
          :label="$t('email')"
          id="email"
          name="email"
          type="email"
          v-model="email"
          class="w-full"
        />
        <span class="text-danger text-sm">{{ errors.first('email') }}</span>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="float-right" type="border" @click="cancel"
          >{{ $t('action.cancel') }}</vs-button
        >
        <vs-button
          v-permission="'students.create'"
          :disabled="!validateForm"
          class="float-right mr-2"
          @click="create"
          >{{ $t('action.save') }}</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>

<script>
import StudentService from '@/services/api/StudentService'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

export default {
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  components: {
    datetime: Datetime
  },
  data: () => ({
    id: null,
    name: '',
    email: '',
    birthdate: '',
    gender: '',
    service: null
  }),
  computed: {
    validateForm() {
      return (
        !this.isEmpty(this.name) &&
        !this.isEmpty(this.email) &&
        !this.isEmpty(this.birthdate) &&
        !this.isEmpty(this.gender)
      )
    }
  },
  methods: {
    create() {
      this.clearErrors(this.$validator)
      this.$vs.loading()

      this.service
        .createOrUpdateExtended({
          id: this.id,
          name: this.name,
          birthdate: this.birthdate,
          gender: this.gender,
          email: this.email
        })
        .then(
          data => {
            this.$vs.loading.close()
            this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
            this.$router.push(`/students/${data.student.id}/edit`)
          },
          error => {
            this.$vs.loading.close()
            this.showErrors(this.$validator, error)
            this.notifyError(this.$vs, this.$t('ocorreu-um-erro'))
          }
        )
    },
    cancel() {
      this.$router.push('/students')
    },
    getData(id) {
      this.$vs.loading()
      this.service.readExtended(id).then(
        data => {
          this.name = data.name
          this.birthdate = data.birthdate
          this.gender = data.gender
          this.email = data.email
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    }
  },
  beforeMount() {
    this.service = StudentService.build(this.$vs)
    if (this.id) {
      this.getData(this.id)
    }
  }
}
</script>
